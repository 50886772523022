import companyFilters from '@/app/dashboard/companies/filters';
import { cn } from '@/lib/cn';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  CustomerEarningsRevenueSettingConfig,
  type CustomerEarningsRevenueSetting,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './types';
import { toPercentageString } from '@/lib/utils';

export const CustomerEarningsSettingsItem: RelationFieldProps<CustomerEarningsRevenueSetting> =
  {
    nested: getRelationFieldNames(CustomerEarningsRevenueSettingConfig),
    filters: companyFilters,
    renderItem: (item, isRelationField) => ({
      title: item?.name,
      descriptionItems: (
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span>
            {item.quota ? toPercentageString(item.quota) : 'Ingen kvot angiven'}
          </span>
          {!isRelationField && <Dot />}
          <span className='font-medium'>
            {item.article?.name ?? 'Inge artikel angiven'}
          </span>
          {!isRelationField && <Dot />}
          <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
        </div>
      ),
    }),
  };
