import { Icons } from '@/components/icons';
import {
  defaultBooleanFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { ArticleCategoryConfig, ArticleConfig } from '@pigello/pigello-matrix';
import { archivedFilter } from '../utils';

const articleRentFilters: Filter[] = [
  archivedFilter,
  {
    ...defaultStringFilter,
    title: ArticleConfig.fields.name.verboseName,
    externalFieldName: ArticleConfig.fields.name.externalFieldName,
  },
  {
    ...defaultRelationFilter,
    title: ArticleConfig.fields.category.verboseName,
    externalFieldName: ArticleConfig.fields.category.externalFieldName,
    relationModelName: ArticleCategoryConfig.modelName,
    icon: Icons.category,
  },
  {
    ...defaultStringFilter,
    title: ArticleConfig.fields.revenueLossAccount.verboseName,
    externalFieldName:
      ArticleConfig.fields.revenueLossAccount.externalFieldName,
  },
  {
    ...defaultSelectFilter,
    title: ArticleConfig.fields.vat.verboseName,
    externalFieldName: ArticleConfig.fields.vat.externalFieldName,
    choices: ArticleConfig.fields.vat.choices,
  },
  {
    ...defaultBooleanFilter,
    title: ArticleConfig.fields.ignoreDynamiclyCalculatedVat.verboseName,
    externalFieldName:
      ArticleConfig.fields.ignoreDynamiclyCalculatedVat.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ArticleConfig.fields.allowSpaceBoundRevenueRows.verboseName,
    externalFieldName:
      ArticleConfig.fields.allowSpaceBoundRevenueRows.externalFieldName,
  },
  {
    ...defaultBooleanFilter,
    title: ArticleConfig.fields.archived.verboseName,
    externalFieldName: ArticleConfig.fields.archived.externalFieldName,
  },
];

export { articleRentFilters };
