import {
  defaultEnumFilter,
  type Filter,
} from '@/components/table-filters/constants';
import { ArticleCategoryConfig } from '@pigello/pigello-matrix';

export const revenueAccount: Record<
  number,
  | 'revenueVat0Account'
  | 'revenueVat6Account'
  | 'revenueVat12Account'
  | 'revenueVat25Account'
> = {
  0: 'revenueVat0Account',
  6: 'revenueVat6Account',
  12: 'revenueVat12Account',
  25: 'revenueVat25Account',
} as const;

export const archivedFilter: Filter = {
  ...defaultEnumFilter,
  title: ArticleCategoryConfig.fields.archived.verboseName,
  externalFieldName: ArticleCategoryConfig.fields.archived.externalFieldName,
  enumChoices: [
    {
      filters: [{ operator: 'noop', value: 'false' }],
      title: 'Aktiv',
      badgeVariant: 'green',
    },
    {
      filters: [{ operator: 'noop', value: 'true' }],
      title: 'Arkiverad',
      badgeVariant: 'destructive',
    },
  ],
};
