import type { BadgeVariant } from '../ui/badge';

export type StatusType = 'tenant' | 'contract' | 'object';

type Status = {
  text: string;
  color: BadgeVariant['variant'];
};

export const TENANT_ACTIVE_STATUSES = [0, 1, 2, 3, 4, 5, 7, 8];

export const StatusMap: Record<StatusType, Record<number, Status>> = {
  tenant: {
    0: { text: 'Aktiv', color: 'green' },
    1: { text: 'Aktiv', color: 'green' },
    2: { text: 'Aktiv', color: 'green' },
    3: { text: 'Aktiv', color: 'green' },
    4: { text: 'Aktiv', color: 'green' },
    5: { text: 'Aktiv', color: 'green' },
    6: { text: 'Avflyttande', color: 'destructive' },
    7: { text: 'Aktiv', color: 'green' },
    8: { text: 'Aktiv', color: 'green' },
    9: { text: 'Kommande', color: 'blue' },
    10: { text: 'Kommande (ej signerad)', color: 'blue' },
    11: { text: 'Avflyttande (ej signerad)', color: 'yellow' },
    12: { text: 'Inaktiv', color: 'outline' },
  },
  contract: {
    0: { text: 'Aktivt', color: 'green' },
    1: { text: 'Aktivt (Tillsvidare)', color: 'green' },
    2: { text: 'Aktivt (Tidsbestämt)', color: 'green' },
    3: {
      text: 'Uppsagt för villkorsändring (Ej bekräftad)',
      color: 'destructive',
    },
    4: { text: 'Uppsagt för avflytt (Ej bekräftad)', color: 'destructive' },
    5: { text: 'Uppsagt för villkorsändring', color: 'destructive' },
    6: { text: 'Uppsagt för avflytt', color: 'destructive' },
    7: { text: 'Utgående', color: 'yellow' },
    8: { text: 'Utgående', color: 'yellow' },
    9: { text: 'Kommande', color: 'blue' },
    10: { text: 'Ej signerat', color: 'purple' },
    11: { text: 'Uppsägning ej signerad', color: 'light-gray' },
    12: { text: 'Tidigare', color: 'gray' },
  },
  object: {
    0: { text: 'Uthyrd', color: 'green' },
    1: { text: 'Uthyrd (Tillsvidare)', color: 'green' },
    2: { text: 'Uthyrd (Tidsbestämt)', color: 'green' },
    3: { text: 'Uthyrd (Uppsagt för villkorsändring)', color: 'destructive' },
    4: { text: 'Uthyrd (Uppsagt för avflytt)', color: 'destructive' },
    5: { text: 'Uthyrd (Uppsagt för villkorsändring)', color: 'destructive' },
    6: { text: 'Uthyrd (Uppsagt för avflytt)', color: 'destructive' },
    7: { text: 'Uthyrd (Utgående)', color: 'yellow' },
    8: { text: 'Uthyrd (Utgående)', color: 'yellow' },
    9: { text: 'Vakant (Kommande)', color: 'destructive' },
    10: { text: 'Vakant', color: 'destructive' },
    11: { text: 'Vakant', color: 'destructive' },
    12: { text: 'Vakant', color: 'destructive' },
  },
};

type BadgeProp = {
  text: string;
  variant: BadgeVariant['variant'];
};

export const readableStatusMonitoring: Record<number, BadgeProp> = {
  666: {
    text: 'Okänd',
    variant: 'destructive',
  },
  0: {
    text: 'Skapades',
    variant: 'green',
  },
  1: {
    text: 'Uppdaterades',
    variant: 'yellow',
  },
  2: {
    text: 'Raderades',
    variant: 'destructive',
  },
};
