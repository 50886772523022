import type { BadgeVariant } from '@/components/ui/badge';
import type {
  BaseContractRevenueRow,
  BaseRevenueRow,
  BaseSpaceRevenueRow,
  BlockContract,
  ModelName,
} from '@pigello/pigello-matrix';
import {
  ApartmentConfig,
  ApartmentContractConfig,
  ApartmentContractRevenueRowConfig,
  ApartmentRevenueRowConfig,
  BlockContractConfig,
  BlockContractRevenueRowConfig,
  BrfApartmentConfig,
  BrfApartmentInvoiceConfigurationConfig,
  BrfApartmentInvoiceConfigurationRevenueRowConfig,
  BrfApartmentRevenueRowConfig,
  IndustrialPremisesConfig,
  IndustrialPremisesContractConfig,
  IndustrialPremisesContractRevenueRowConfig,
  IndustrialPremisesRevenueRowConfig,
  OtherContractConfig,
  OtherContractRevenueRowConfig,
  OutdoorSectionConfig,
  OutdoorSectionContractConfig,
  OutdoorSectionContractRevenueRowConfig,
  OutdoorSectionRevenueRowConfig,
  ParkingSpotConfig,
  ParkingSpotContractConfig,
  ParkingSpotContractRevenueRowConfig,
  ParkingSpotRevenueRowConfig,
} from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import type { ICombinedRow } from './overview/types';

export type DisplayModes = 'past' | 'present' | 'future';

export const revenueRowStatuses: Record<string, number> = {
  ACTIVE: 0,
  PAUSED: 1,
  PAST: 2,
  FUTURE: 3,
};

export const revenueRowStatusLabels: Record<number, string> = {
  0: 'Aktiv',
  1: 'Pausad',
  2: 'Tidigare',
  3: 'Framtida',
};

export const revenueRowStatusColors: Record<number, BadgeVariant['variant']> = {
  0: 'green',
  1: 'light-gray',
  2: 'gray',
  3: 'blue',
};

export const revenueRowStatusColorsNew: Record<number, string> = {
  0: 'bg-green-500',
  1: 'bg-gray-500',
  2: 'bg-gray-500',
  3: 'bg-blue-500',
};

export function calculateRevenueRowStatus(
  row: BaseRevenueRow,
  period?: { start: DateTime; end: DateTime }
) {
  const baseDate = DateTime.now();
  if (!period) {
    return revenueRowStatuses.ACTIVE;
  }
  if (!row.startDate && !row.endDate) {
    return revenueRowStatuses.ACTIVE;
  }
  if (row.startDate && DateTime.fromISO(row.startDate) > period.end) {
    return revenueRowStatuses.FUTURE;
  }
  if (row.endDate && DateTime.fromISO(row.endDate) < period.start) {
    return revenueRowStatuses.PAST;
  }

  if (row.excemptFromMonths?.includes(baseDate.month)) {
    return revenueRowStatuses.PAUSED;
  }

  return revenueRowStatuses.ACTIVE;
}

export function calculateRevenueRowsForMonth({
  instances,
  selectedBaseMonth,
  displayMode,
}: {
  instances: BaseRevenueRow[];
  selectedBaseMonth: DateTime;
  displayMode: DisplayModes;
}) {
  return instances
    .filter((row) => {
      const startsAfterBaseDate =
        row.startDate &&
        DateTime.fromISO(row.startDate) > selectedBaseMonth.endOf('month');
      const endsBeforeBaseDate =
        row.endDate &&
        DateTime.fromISO(row.endDate) < selectedBaseMonth.startOf('month');
      const isActiveNow =
        (!row.startDate && !row.endDate) ||
        (!startsAfterBaseDate && !endsBeforeBaseDate);

      // remove past rows if not show past
      if (displayMode !== 'past' && endsBeforeBaseDate) {
        return false;
      }

      // remove future rows if not show future
      if (displayMode !== 'future' && startsAfterBaseDate) {
        return false;
      }

      if (displayMode !== 'present' && isActiveNow) {
        return false;
      }

      return true;
    })
    .map((row) => {
      const status = calculateRevenueRowStatus(row);

      return {
        ...row,
        status,
        statusLabel: revenueRowStatusLabels[status],
      };
    });
}

const calculatePeriodQuota = (
  rowStart: DateTime | null,
  rowEnd: DateTime | null,
  periodStart: DateTime,
  periodEnd: DateTime
): number => {
  // Step 1: Determine effective start and end dates
  const effectiveStart = rowStart
    ? DateTime.max(rowStart, periodStart)
    : periodStart;
  const effectiveEnd = rowEnd ? DateTime.min(rowEnd, periodEnd) : periodEnd;

  // Step 2: Calculate days in start and end months
  const daysInStartMonth = effectiveStart.daysInMonth;
  const daysInEndMonth = effectiveEnd.daysInMonth;

  // Step 3: Check if there are partial months at start or end
  const hasCutoffStart = effectiveStart.day !== 1;
  const hasCutoffEnd = effectiveEnd.day !== daysInEndMonth;

  // Step 4: Check if start and end are in the same month
  const isSameMonth = effectiveStart.hasSame(effectiveEnd, 'month');

  // Step 5: Calculate total months between start and end dates
  let months =
    (effectiveEnd.year - effectiveStart.year) * 12 +
    effectiveEnd.month -
    effectiveStart.month +
    1;

  // Step 6: Adjust for partial start month
  if (hasCutoffStart) {
    months -= 1;

    let interval: number;
    if (isSameMonth) {
      interval = effectiveEnd.day - effectiveStart.day + 1;
    } else {
      interval = (daysInStartMonth ?? 30) - effectiveStart.day + 1;
    }

    const startMonthQuota = interval / (daysInStartMonth ?? 30);
    months += startMonthQuota;
  }

  // Step 7: Adjust for partial end month
  if (hasCutoffEnd) {
    months -= 1;

    const endMonthQuota = effectiveEnd.day / (daysInEndMonth ?? 30);
    months += endMonthQuota;
  }

  return months;
};

export interface SelectPeriod {
  start: DateTime;
  end: DateTime;
}

const getTotalForRow = ({
  row,
  selectedPeriod,
}: {
  row: BaseSpaceRevenueRow | BaseContractRevenueRow;
  selectedPeriod: SelectPeriod;
}) => {
  const rowStart = row.startDate
    ? DateTime.fromISO(row.startDate as string)
    : null;
  const rowEnd = row.endDate ? DateTime.fromISO(row.endDate as string) : null;

  const quota = calculatePeriodQuota(
    rowStart,
    rowEnd,
    selectedPeriod.start,
    selectedPeriod.end
  );
  const adjustedValue = row.unitAmount * (row.unitPrice ?? 0) * quota;

  const monthNumbers: number[] = [];

  const numMonths = selectedPeriod.end.diff(
    selectedPeriod.start,
    'months'
  ).months;
  for (let i = 0; i < numMonths; i++) {
    monthNumbers.push(selectedPeriod.start.plus({ months: i }).month);
  }

  // remove excempt months from the row
  const numOfExcemptMonths = monthNumbers.filter((month) =>
    row.excemptFromMonths?.includes(month)
  )?.length;

  return (
    adjustedValue - row.unitAmount * (row.unitPrice ?? 0) * numOfExcemptMonths
  );
};

type GetTotalHelper = (
  rows: ICombinedRow[],
  selectedPeriod: SelectPeriod
) => number;

const calculateTotalForCondition = (
  rows: ICombinedRow[],
  condition: (cur: ICombinedRow) => boolean,
  selectedPeriod: SelectPeriod
) =>
  rows.reduce((acc, cur) => {
    if (condition(cur)) {
      return (acc += getTotalForRow({
        row: cur,
        selectedPeriod: selectedPeriod,
      }));
    }
    return acc;
  }, 0);

export const getTotalForPeriod: GetTotalHelper = (rows, selectedPeriod) =>
  calculateTotalForCondition(rows, () => true, selectedPeriod);

export const getContractTotalIndexAddon: GetTotalHelper = (
  rows,
  selectedPeriod
) =>
  calculateTotalForCondition(
    rows,
    (cur) => 'contract' in cur && !!cur.indexationUsage,
    selectedPeriod
  );

export const getTotalBaseRent: GetTotalHelper = (rows, selectedPeriod) =>
  calculateTotalForCondition(
    rows,
    (cur) => !!cur.article?.considerBaseRent,
    selectedPeriod
  );

export const getTotalAddon: GetTotalHelper = (rows, selectedPeriod) =>
  calculateTotalForCondition(
    rows,
    (cur) => !cur.article?.considerBaseRent,
    selectedPeriod
  );

export const getTotalDistributionRent: GetTotalHelper = (
  rows,
  selectedPeriod
) =>
  calculateTotalForCondition(
    rows,
    (cur) => !!cur.distributionQuota,
    selectedPeriod
  );

export const getTotalDiscountForPeriod: GetTotalHelper = (
  rows,
  selectedPeriod
) =>
  calculateTotalForCondition(
    rows,
    (cur) => cur.unitPrice != null && cur.unitPrice < 0,
    selectedPeriod
  );

export const getContractRowModelName = (modelName?: ModelName) => {
  switch (modelName) {
    case ApartmentContractConfig.modelName:
      return ApartmentContractRevenueRowConfig.modelName;
    case IndustrialPremisesContractConfig.modelName:
      return IndustrialPremisesContractRevenueRowConfig.modelName;
    case OutdoorSectionContractConfig.modelName:
      return OutdoorSectionContractRevenueRowConfig.modelName;
    case ParkingSpotContractConfig.modelName:
      return ParkingSpotContractRevenueRowConfig.modelName;
    case BlockContractConfig.modelName:
      return BlockContractRevenueRowConfig.modelName;
    case OtherContractConfig.modelName:
      return OtherContractRevenueRowConfig.modelName;
    case BrfApartmentInvoiceConfigurationConfig.modelName:
      return BrfApartmentInvoiceConfigurationRevenueRowConfig.modelName;
    default:
      return null;
  }
};

export const getSpaceRowModelName = (modelName?: ModelName) => {
  switch (modelName) {
    case ApartmentConfig.modelName:
      return ApartmentRevenueRowConfig.modelName;
    case IndustrialPremisesConfig.modelName:
      return IndustrialPremisesRevenueRowConfig.modelName;
    case OutdoorSectionConfig.modelName:
      return OutdoorSectionRevenueRowConfig.modelName;
    case ParkingSpotConfig.modelName:
      return ParkingSpotRevenueRowConfig.modelName;
    case BrfApartmentConfig.modelName:
      return BrfApartmentRevenueRowConfig.modelName;
    default:
      return null;
  }
};

// If it is blockcontract, extract the apartments, industrial premises, outdoor sections and parking spots
export const getBlockContractSpaceRows = (
  blockContract: BlockContract | undefined
) => {
  if (!blockContract) return undefined;

  const apartments = {
    spaceIds: blockContract?.apartments?.map((a) => a?.id) ?? [],
    spaceRowModelName: getSpaceRowModelName(ApartmentConfig.modelName),
    verboseName: ApartmentConfig.verboseName,
  };
  const industrialPremises = {
    spaceIds: blockContract?.industrialPremises?.map((i) => i?.id) ?? [],
    spaceRowModelName: getSpaceRowModelName(IndustrialPremisesConfig.modelName),
    verboseName: IndustrialPremisesConfig.verboseName,
  };
  const outdoorSections = {
    spaceIds: blockContract?.outdoorSections?.map((o) => o?.id) ?? [],
    spaceRowModelName: getSpaceRowModelName(OutdoorSectionConfig.modelName),
    verboseName: OutdoorSectionConfig.verboseName,
  };
  const parkingSpots = {
    spaceIds: blockContract?.parkingSpots?.map((p) => p?.id) ?? [],
    spaceRowModelName: getSpaceRowModelName(ParkingSpotConfig.modelName),
    verboseName: ParkingSpotConfig.verboseName,
  };

  return [apartments, industrialPremises, outdoorSections, parkingSpots];
};
